import './css/experience.css'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Loading2 from './Loading2'

const Experience = () => {

  const [loading, setLoading] = useState(true)
  const [experienceYears, setExperienceYears] = useState('')
  const [experienceYearsImage, setExperienceYearsImage] = useState('')
  const [experienceClientes, setExperienceClientes] = useState([])
  const [experienceSocios, setExperienceSocios] = useState([])



  useEffect(() => {

    getExperience()
    setLoading(false)
  }, [])

  const getExperience = async () => {
    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/experience'
    })

    setExperienceYears(result.data.years)
    setExperienceYearsImage(result.data.yearsBackground.url)
    setExperienceClientes(result.data.clientes)
    setExperienceSocios(result.data.socios)

  }

  return (

    loading ? <div className="Experience"><Loading2 /></div> :
      <div className="Experience">
        <div className="years-experience">
          {experienceYearsImage ? <div className="years-container1" style={{ backgroundImage: `url(${experienceYearsImage})` }}>{experienceYears}</div> :
            <div className="years-container1">{experienceYears}</div>}
          <div className="years-container2"><span className="red-font">AÑOS DE </span>EXPERIENCIA</div>

        </div>

        <Tabs className="our-exp-tabs" defaultActiveKey="clients" id="our-experience">
          <Tab eventKey="clients" title="NUESTROS CLIENTES">
            <div className="Logos-container">
            <div className="Logos-container-scrollable">
              {experienceClientes.map((client) => {
                return (
                  <div key={client._id} className="client-logo">
                    <img src={client.url} alt="logo" style={{ width: '100%' }} />
                  </div>
                )
              })}
              </div>
            </div>
          </Tab>
          <Tab eventKey="partners" title="NUESTROS SOCIOS">
            <div className="Logos-container">
            <div className="Logos2-container-scrollable">
              {experienceSocios.map((partner) => {
                return (
                  <div key={partner._id} className="client-logo">
                    <img src={partner.url} alt="logo" style={{ width: '100%' }} />
                  </div>
                )
              })}
              </div>
            </div>
          </Tab>
        </Tabs>

      </div>
  )
}

export default Experience
