
import './css/MobileMenu.css'
import { HashLink as Link } from 'react-router-hash-link'
import  Navbar  from 'react-bootstrap/Navbar'
import  Nav  from 'react-bootstrap/Nav'



// const MobileMenu = () => {
//   const [ open, setOpen ] = useState(false)
  
//   const toggle = () => {
//     console.log('bang')
//     setOpen(!open)
//   }
//   return (
//     <>
//       <div className="MobileMenu">
//         <div className="mobile-menu-bar">
//           <div className="logo" style={{ width: '60px', height: '60px' }}><Link to="/"><img className="mobile-logo" src="/logo.png" alt="logo" /></Link></div>
//           <div onClick={() => { toggle() }} className="toggler" style={{ width: '60px', height: '60px' }}><GiHamburgerMenu /></div>
//         </div>
//         <div className="menu-body">

//         </div>
//       </div>
//     </>
//   )
// }


const MobileMenu = () => {
 
  return (
    
    <Navbar className="MobileMenu navbar-dark" collapseOnSelect expand="lg" sticky="top">
      <Navbar.Brand as={Link} to="/">
        <div className="mobile-logo-container">
          <img className="mobile-logo" src="/logo.png" alt="logo" />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle className="navbar-dark" aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/#somos">SOMOS</Nav.Link>
          <Nav.Link as={Link} to="/#hacemos">HACEMOS</Nav.Link>
          <Nav.Link as={Link} to="/#esencia">NUESTRA ESENCIA</Nav.Link>
          <Nav.Link as={Link} to="/#proyectos">PROYECTOS</Nav.Link>
          <Nav.Link as={Link} to="/blog">BLOG</Nav.Link>
          <Nav.Link as={Link} to="/#contacto">CONTACTO</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  
)
}



export default MobileMenu
