
import React, { useContext } from 'react'


  import { HomeContext } from '../context/HomeContext'


const Projects = (props) => {

  
  
  const { content, essence } = props;
  const homeContext = useContext(HomeContext)

  

  

  return (
    <div id="esencia" >

      <div className="Sections-Intro">
        <span className="section-title">Nuestra <span className="red-font">Esencia</span></span>
        <span className="center">{essence}</span>
      </div>

      <div id="proyectos" className="Proyects">
        { content.sort((a , b) => b.slug - a.slug ).map((project) => {
          return (
            project.imagenDestacada ? <div onClick={( () => {
              homeContext.openLightbox(project)
            } )} id={project._id} key={project._id} className="project-container" style={{ backgroundImage: `url(${project.imagenDestacada.url})` }} >
              <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%' }}>
                <p className="Project-title-turned">{project.title}</p>
              </div>
            </div> :
              <div id={project._id} key={project._id} className="project-container">
                <div style={{ backgroundColor: 'rgba(0,0,0,0.6)', width: '100%', height: '100%' }}>
                  <p className="Project-title-turned">{project.title}</p>
                </div>
              </div>
          )
        })}
      </div>

    </div>
  )
}

export default Projects
