import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import Header from './components/Header'
import MobileMenu from './components/MobileMenu'
import Contact from './components/Contact'
import Footer from './components/Footer'

import Home from './pages/Home'
import Blog from './pages/Blog'
import BlogEntry from './pages/BlogEntry'

import { HomeContextProvider } from './context/HomeContext'

import GA from './utils/GoogleAnalytics'



function App() {



  return (

    <div className="App">
      <BrowserRouter>
      { GA.init() && <GA.RouteTracker /> }
        <Header />
        <MobileMenu />  
        <HomeContextProvider>
          <Switch>
            <Route exact path={'/'} component={Home} />
            <Route exact path={'/blog'} component={Blog} />
            <Route exact path={'/blog-entry/:slug'} component={BlogEntry} />
            <Redirect from='*' to='/' />
          </Switch>
        </HomeContextProvider>
        <Contact />
        <Footer />
      </BrowserRouter>
      
    </div>

  );
}

export default App;
