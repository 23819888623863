import React from 'react'

const Loading = () => {
  return (
    <div className="Loading-Screen">
      <img className="loading-gif" src="/loading.gif" alt="loading" />
    </div>
  )
}

export default Loading
