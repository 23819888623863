import React, { useState, useEffect } from 'react'
import axios from 'axios'

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

// import { Link } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {

  const [logo, setLogo] = useState('')

  useEffect(() => {
    getBrands()
  }, [])


  const getBrands = async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/brands'
    })
    result.data.headerLogo ?  setLogo(result.data.headerLogo.url) : setLogo('empty')
  }



  return (
    <Navbar className="Header" expand="lg">
      <Nav className="mr-auto">
        <Navbar.Brand><Link to={'/'}>
        
          <img className="Header-Logo" src={logo} alt="" />
        </Link></Navbar.Brand>
      </Nav>
      <Nav className="mr-auto menu-items">
        <Link smooth className="white-link menu-item" to={'/#somos'}>somos</Link>
        <Link smooth className="white-link menu-item" to={'/#hacemos'}>hacemos</Link>
        <Link smooth className="white-link menu-item" to={'/#esencia'}>nuestra esencia</Link>
        <Link smooth className="white-link menu-item" to={'/#proyectos'}>proyectos</Link>
        <Link smooth className="white-link menu-item" to={'/blog'}>blog</Link>
        <Link smooth className="white-link menu-item last-item" to={'#contacto'}>contacto</Link>
      </Nav>
    </Navbar>
  )
}

export default Header
