import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Loading2 from './Loading2'


import Carousel from 'react-bootstrap/Carousel'
import { FaLinkedin } from "react-icons/fa";



const Slider = () => {

  const [ featureds, setFeatureds ] = useState([])
  const [ loading, setLoading ] = useState(true)

  useEffect(() => {

    getFeatureds()
    setLoading(false)
  },[])


  const getFeatureds = async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/projects'
    })
    setFeatureds(result.data.filter((project) => project.featured === true))
  }

  


  return (

    loading ? <Loading2 /> :
    <div className="Slider">
      <div className="SliderCol1">
        <div className="mar-vol-container">
          <div className="slide-counter">{/* <span>#</span>/<span>#</span>*/}</div>
          <div className="mar-vol-text">marcas con volumen</div>
          <div><button target="_blank" rel="noopener" href="" className="linkedin"><FaLinkedin /></button></div>
        </div>
      </div>

      <div className="SliderCol2">
        <Carousel className="custom-carousel">

          {featureds.map((featured) => {
            return (

              featured.imagenDestacada ? 
              <Carousel.Item key={featured._id} style={{ backgroundImage: `url(${featured.imagenDestacada.url})` }} className="slide">
                <div style={{backgroundColor:'rgba(0,0,0,0.6)', width:'100%', height:'100%'}}>

                  <Carousel.Caption>
                    <div className="Slide-info">
                      <h3>{featured.textoPrincipal}</h3>
                      <p>{featured.textoSecundario}</p>

                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item> :

              <Carousel.Item key={featured.id} className="slide">
                <div style={{backgroundColor:'rgba(0,0,0,0.6)', width:'100%', height:'100%'}}>

                  <Carousel.Caption>
                    <div className="Slide-info">
                      <h3>{featured.textoPrincipal}</h3>
                      <p>{featured.textoSecundario}</p>

                    </div>
                  </Carousel.Caption>
                </div>
              </Carousel.Item>
            )
          })}
        </Carousel>
      </div>
    </div>
  )
}

export default Slider



