import { useEffect, useState, useContext } from 'react'
import axios from 'axios'


import { HomeContext } from '../context/HomeContext'

import Loading from '../components/Loading'

import Slider from '../components/Slider'
import AboutUs from '../components/AboutUs'
import Projects from '../components/Projects'
import Experience from '../components/Experience'

import { FaRegTimesCircle } from "react-icons/fa";

import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";


const Home = () => {

  const [loading, setLoading] = useState(true)
  const [essenceText, setEssenceText] = useState('')
  const [projects, setProjects] = useState([])

  const homeContext = useContext(HomeContext)

  const getProjects = async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/projects'
    })
    setProjects(result.data)
    setLoading(false)
  }

  const getAboutUs = async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/about-us'
    })
    // setProjects(result.data)
    setEssenceText(result.data.essenceText)
    setLoading(false)
  }


  useEffect(() => {

    getProjects()
    getAboutUs()
    window.scrollTo(0, 0)

  }, [])



  return (

    loading ? <Loading /> :
      <>

        <Slider />
        <AboutUs />
        <Projects content={projects} essence={essenceText}/>
        <Experience />


        { homeContext.isActive && <div id="lightbox">
        <span className="slider-prev slider-arrow" onClick={() => homeContext.prevSlide()}> <FaArrowLeft /></span>
          <div className="light-box-imagescontainer">
            <div className="lightbox-active-slide-container">
              <img className="lightbox-active-picture" src={homeContext.slide} alt="" />
            </div>
          </div>
          <span className="slider-next slider-arrow" onClick={() => homeContext.nextSlide()}><FaArrowRight /></span>
          <span onClick={() => homeContext.closeLightbox()} className="exit-lightbox"><FaRegTimesCircle /></span>
        </div>}

      </>
  )
}

export default Home


