import React, { useState, useEffect } from 'react'
import axios from 'axios'

import FooterBox1 from './FooterBox1'
import FooterBox2 from './FooterBox2'

const Footer = () => {
  const [logo, setLogo] = useState('')
  const [phone, setPhone] = useState('')
  const [fax, setFax] = useState('')
  const [mobile, setMobile] = useState('')
  const [address, setAddress] = useState('')

  useEffect(() => {
    getBrands()
  }, [])


  const getBrands = async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/brands'
    })

    setLogo(result.data.footerLogo.url)
    setPhone(result.data.phone)
    setFax(result.data.fax)
    setMobile(result.data.mobile)
    setAddress(result.data.address)
    
  }
  return (
    <div className="Footer">
      <FooterBox1 phoneNumber={phone} faxNumber={fax} mobileNumber={mobile} addressNumber={address} />
      <FooterBox2 footerLogo={logo} />
    </div>
  )
}

export default Footer
