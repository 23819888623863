import React from 'react'

const FooterBox1 = (props) => {

  const { phoneNumber, faxNumber  } = props
  return (
    <div className="Footer-box1">
      <div className="Footer-box1-container">
        <span className="footer-data"><span className="red-footer-title">Teléfono: </span> <a className="white-link" href={`tel:${phoneNumber}`}>{ phoneNumber }</a></span>
        <span className="footer-data"><span className="red-footer-title">Telefax: </span> <a className="white-link" href={`tel:${faxNumber}`}>{ faxNumber }</a></span>
        {/* <span className="footer-data"><span className="red-footer-title">Celular: </span> <a className="white-link" href={`tel:${mobileNumber}`}>{ mobileNumber }</a></span>
        <span className="footer-data"><span className="red-footer-title">Dirección: </span><span className="white-link">{ addressNumber }</span></span> */}
      </div>
    </div>
  )
}

export default FooterBox1
