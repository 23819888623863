import React, { createContext, useState } from 'react'


export const HomeContext = createContext();



export function HomeContextProvider({ children }) {

  

  const [counter, setCounter] = useState(0)
  const [slide, setSlide] = useState('')
  const [slides, setSlides] = useState([])
  const [isActive, setIsActive] = useState(false)

  const openLightbox = (data) => {
    setSlide(data.galeria[counter].url)
    setSlides(data.galeria)
    setIsActive(true)
  }

  function closeLightbox() {
    setSlides([])
    setCounter(0)
    
    setSlide('')
    setIsActive(false)
    
  }

  function nextSlide() {

    if (counter < slides.length - 1) {
      setSlide(slides[counter + 1 ].url)
      setCounter(counter + 1)
    } else {
      // console.log('termina aquí')
    }

  }


  function prevSlide() {

    if (counter > 0 && counter <= slides.length - 1) {
      setSlide(slides[counter - 1].url)
      setCounter(counter - 1 )
    }


  }

  return (
    <HomeContext.Provider value={{
      counter,
      slide,
      isActive,
      openLightbox,
      closeLightbox,
      nextSlide,
      prevSlide

    }}>
      {children}
    </HomeContext.Provider>
  )
}