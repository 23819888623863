import React from 'react'

const FooterBox2 = (props) => {

  const { footerLogo } = props
  return (
    <div className="Footer-box2">
      <div className="Footer-box2-container">
        <div className="footer-logo-conainer"><img className="Footer-Logo" src={footerLogo} alt="logo" /></div>
        <div className="copyright">© TR3S 2021 | TODOS LOS DERECHOS RESERVADOS</div>
      </div>
    </div>
  )
}

export default FooterBox2



