import axios from 'axios'
import React, { useState, useEffect } from 'react'
import Loading from '../components/Loading'

import { Link } from 'react-router-dom'

const Blog = () => {

  const [loading, setLoading] = useState(true)
  const [blogs, setBlogs] = useState([])



  const getBlogs = async () => {
    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/blogs'
    })
    setBlogs(result.data)
    
    setLoading(false)
  }

  useEffect(() => {
    getBlogs()
    window.scrollTo(0, 0)
  }, [])

  return (
    loading ? <Loading /> :

      <div id="Blog" className="Blog">
        {blogs.map((blog) => {
          return (
            <div key={blog._id} className="Blog-container">

              <div className="Blog-Entry">
                <div className="Blog-Entry-Container Blog-Entry-Image-Container">
                  <img className="blog-entry-image" src={blog.featuredImage.url} alt="featured" />
                </div>
                <div className="Blog-Entry-Container Blog-Entry-Text-container">
                  <h2>{blog.title}</h2>
                  <div className="Entry-Featured-Text" dangerouslySetInnerHTML={{ __html: blog.featuredText }}></div>
                  <div className="View-Entry-Button-Container"><Link to={`/blog-entry/${blog.slug}`}><span className="submit-button">Ver más</span></Link></div>
                </div>

              </div>

            </div>
          )
        })}
      </div>
  )
}

export default Blog
