import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'

const BlogEntry = () => {

  const params = useParams()

  const [entry, setEntry] = useState({})
  const [loading, setLoading] = useState(true)


  const getEntry = useCallback(async () => {

    let result = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: `/blogs?slug=${params.slug}`
    })
    setEntry(result.data[0])
    // console.log(result.data[0].featuredImage.url)
    setLoading(false)
    window.scrollTo(0, 0)
  }

  )

  useEffect(() => {
    getEntry()

  }, [])

  return (
    loading ? <Loading /> :
      <div id="Blog" className="Blog">
        <div className="Blog-Entry-inner">

          <div className="Entry-Head" style={{ backgroundImage: `url(${entry.featuredImage.url})` }}>

          </div>
          <div className="Entry-Content">
            <div className="Entry-Content-Title">{entry.title}</div>
            <div className="Entry-Content-Featured-Text" dangerouslySetInnerHTML={{ __html: entry.featuredText }}></div>

            <div className="Entry-Content-inner" dangerouslySetInnerHTML={{ __html: entry.content }}></div>
          </div>
          <div className="Entry-Footer"></div>
        </div>
      </div>

  )
}

export default BlogEntry
