import React, { useState } from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {


  
  const [ name, setName ] = useState('')
  const [ number, setNumber ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ company, setCompany ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ sentForm, setSentForm ] = useState(false)

  const handleChange = (e) => {

    e.target.name === "name" ? setName(e.target.value) :
      e.target.name === "email" ? setEmail(e.target.value) :
        e.target.name === "message" ? setMessage(e.target.value) : 
          e.target.name === "number" ? setNumber(e.target.value) : 
            e.target.name === "company" ? setCompany(e.target.value) : console.log('error')
  
  }
  
  const handleSubmit = (e) => {

    e.preventDefault()
    if (name === '' || email === '' || message === '' || number === '' || company === '') {
      sendError()
    }
    else {
      
      sendData()
    }
  
  }

  const sendError = () => {
    console.log('alguno de los campos está vacio')
  }


  const sendData = () => {

    const templateParams = {
      name: name,
      email: email,
      company: company,
      number: number,
      message: message
    };

    const cleanForm = () => {
      document.getElementById('contact-form').reset()
    }

  
    emailjs.send(process.env.REACT_APP_CFORM_EMAIL_SERVICE_ID, process.env.REACT_APP_CFORM_EMAIL_TEMPLATE_ID, templateParams, process.env.REACT_APP_CFORM_EMAIL_USER_ID)
      .then(function (response) {
        console.log('SUCCESS!', response.status, response.text);
        
      }, function (error) {
        // console.log('FAILED...', error);
      });
  
    cleanForm()
    setSentForm(true)
  }

  return (


    <div id="contacto" className="Contact">
      <div className="Sections-Intro">
        <span className="section-title">Nuestra <span className="red-font">Esencia</span></span>
        <span className="center">Hacemos realidad todas las historias y ahora, ¿cuál es la que nos quieres contar?</span>
      </div>
      
      <div className="contact-form-container">
        
        <form id="contact-form" onSubmit={ handleSubmit }>
          { !sentForm && <div className="contact-form">
            <div className="telinput">
              <input onChange={handleChange} name="name" type="text" required placeholder="NOMBRE" />
            </div>
            <div className="phoneinput">
              <input onChange={handleChange} name="number" type="tel" placeholder="TELÉFONO" />
            </div>
            <div className="emailinput">
              <input onChange={handleChange} name="email" type="email" placeholder="E-MAIL" />
            </div>
            <div className="companyinput">
              <input onChange={handleChange} name="company" type="text" placeholder="EMPRESA" />
            </div>
            <div className="historyinput">
              <input onChange={handleChange} name="message" type="text" placeholder="HISTORIA POR CONTAR" />
            </div>
            <div className="g-recaptcha" data-sitekey="6LeayGQaAAAAANsRZ3jnyiDrUOWGVm4OO8i7PAs_"></div> 
            <div className="submitform">
              <button type="submit" className="submit-button">¡ARRAMCAMOS!</button>
            </div>
            </div> }
          
            { sentForm && <div> nos pondremos en contacto contigo pronto! </div>}
        </form>
      
      </div>
    </div>
  )
}

export default Contact