import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Loading2 from './Loading2'

import { AiFillShopping } from "react-icons/ai";
import { GiFlexibleLamp } from "react-icons/gi";
import { FaTheaterMasks } from "react-icons/fa";
import { RiBuilding2Fill } from "react-icons/ri";
import { GiStonePath } from "react-icons/gi";

const AboutUs = (props) => {

  

  const [ loading, setLoading ] = useState(true) 
  const [ about, setAbout ] = useState({})
  
  

  useEffect(() => {

    getAbout()
    setLoading(false)
  },[])

  const getAbout = async () => {

    let result2 = await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/about-us'
    })
  
    setAbout(result2.data)
   
  }


  return (
    loading ? <Loading2 /> :
    <div id="hacemos" className="AboutUs">

      <div className="mid aboutus-col1">
        <div className="about-us-content-container">
          <p>{about.introduction}</p>
          <div className="Abouts-bullets">
            <div className="About-us-row-bullet-container"><span className="Bullet-container"><AiFillShopping className="about-icon" /></span><span className="bullet-info">Locales Comerciales.</span></div>
            <div className="About-us-row-bullet-container"><span className="Bullet-container"><GiFlexibleLamp className="about-icon" /></span><span className="bullet-info">Adecuación y remodelación de oficinas.</span></div>
            <div className="About-us-row-bullet-container"><span className="Bullet-container"><GiStonePath className="about-icon" /></span><span className="bullet-info">Exhibiciones especiales.</span></div>
            <div className="About-us-row-bullet-container"><span className="Bullet-container"><FaTheaterMasks className="about-icon" /></span><span className="bullet-info">Escenografías</span></div>
            <div className="About-us-row-bullet-container"><span className="Bullet-container"><RiBuilding2Fill className="about-icon" /></span><span className="bullet-info">Stands</span></div>
          </div>
        </div>
      </div>

      <div className="mid">

        
        { about.picture ? <div className="Aboutus-picture-container"><img className="Aboutus-picture" src={about.picture.url} alt="" /></div> : <div></div> }
        

      </div>

    </div> 
  )
}

export default AboutUs
