import React from 'react'

const Loading2 = () => {
  return (
    <div className="loading-component">
      <img className="loading-gif" src="/loading.gif" alt="loading" />
    </div>
  )
}

export default Loading2